import Document, { Html, Head, Main, NextScript } from 'next/document'
import Script from 'next/script'
import { cn } from 'utils/classnames'
import { PPSupplyMono } from 'utils/fonts'

export const meta = {
  title: `RFX | Decentralized Perpetual Exchange`,
  description:
    'RFX is a cutting-edge decentralised perpetual futures platform set to revolutionise the derivatives landscape on zkSync Era. RFX stands apart with its forward-thinking architecture, featuring a multi-pool model, advanced low-latency oracle networks, automated risk engine, and innovative veTokenomics. Built upon the principles of capital efficiency, LP risk management, and composability, RFX brings a Uniswap-style "on-rails" permissionless market creation to the derivatives realm.',
  image: '/rfx-og.png',
  type: 'exchange',
}

class MyDocument extends Document {
  render() {
    return (
      <Html className={`${PPSupplyMono.variable} font-sans`}>
        <Head>
          <Script
            async
            src="/datafeeds/udf/dist/bundle.js"
            strategy="beforeInteractive"
          ></Script>
        </Head>
        <body className={cn('hide-scroll')}>
          <Main />
          <NextScript />
        </body>
      </Html>
    )
  }
}

export default MyDocument
