import { Config, watchAccount } from '@wagmi/core'
import { useEffect, useState } from 'react'
import { useAccount } from 'wagmi'

import { DEFAULT_CHAIN_ID, isSupportedChain } from 'config/chains'
import { rainbowKitConfig } from 'utils/rainbow-config'

/**
 * This returns default chainId if chainId is not supported or not found
 */
export function useChainId() {
  let { chainId: unsanitizedChainId } = useAccount()

  const [displayedChainId, setDisplayedChainId] = useState(
    unsanitizedChainId ?? DEFAULT_CHAIN_ID,
  )

  const currentChainIdIsSupported =
    unsanitizedChainId && isSupportedChain(unsanitizedChainId)

  const mustChangeChainId = !currentChainIdIsSupported || !unsanitizedChainId

  useEffect(() => {
    if (currentChainIdIsSupported) {
      setDisplayedChainId(unsanitizedChainId ?? DEFAULT_CHAIN_ID)
      return
    }

    setDisplayedChainId(DEFAULT_CHAIN_ID)
  }, [unsanitizedChainId, currentChainIdIsSupported])

  useEffect(() => {
    if (mustChangeChainId) {
      setDisplayedChainId(DEFAULT_CHAIN_ID)
    }
  }, [mustChangeChainId])

  useEffect(() => {
    const unsubscribe = watchAccount(rainbowKitConfig as Config, {
      onChange: (account) => {
        if (!account.chainId) {
          return
        }
        if (!isSupportedChain(account.chainId)) {
          return
        }

        setDisplayedChainId(account.chainId)
      },
    })

    return () => {
      unsubscribe()
    }
  }, [])

  if (mustChangeChainId) {
    return { chainId: DEFAULT_CHAIN_ID }
  }

  return {
    chainId: displayedChainId,
    isConnectedToChainId: displayedChainId === unsanitizedChainId,
  }
}
