export const CLOUD_FRONT_URL: string =
  'https://d6ywjvkyvtau.cloudfront.net/public'

export const isProd =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ||
  !!process.env.NEXT_PUBLIC_SHOW_PROD_DATA
export const envForThirdPartyTools =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? 'production'
    : 'development'

export const sentryOptions = {
  enabled: envForThirdPartyTools === 'production',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
}
