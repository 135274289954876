import { Config, switchChain } from '@wagmi/core'
import { SELECTED_NETWORK_LOCAL_STORAGE_KEY } from 'config/localStorage'
import { rainbowKitConfig } from 'utils/rainbow-config'

export type NetworkMetadata = {
  chainId: string
  chainName: string
  nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }
  rpcUrls: string[]
  blockExplorerUrls: string[]
}

export async function switchNetwork(chainId: any, active: any) {
  if (active) {
    await switchChain(rainbowKitConfig as Config, { chainId })
  } else {
    // chainId in localStorage allows to switch network even if wallet is not connected
    // or there is no wallet at all
    localStorage.setItem(SELECTED_NETWORK_LOCAL_STORAGE_KEY, String(chainId))
    document.location.reload()
    return
  }
}

export function shortenAddressOrEns(address: string, length: number) {
  if (!length) {
    return ''
  }
  if (!address) {
    return address
  }
  if (address.length < 10) {
    return address
  }
  let left = address.includes('.')
    ? address.split('.')[1].length
    : Math.floor((length - 3) / 2) + 1
  return (
    address.substring(0, left) +
    '...' +
    address.substring(address.length - (length - (left + 3)), address.length)
  )
}
